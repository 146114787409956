import { LinearProgress } from '@material-ui/core';
import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

const Resume = lazy(() => import('./components/Resume'));
const Portfolio = lazy(() => import('./components/Portfolio'));
const Contacts = lazy(() => import('./components/Contacts'));
const Error = lazy(() => import('./components/Error'));
const Home = lazy(() => import('./components/index'));
const LinkTree = lazy(() => import('./components/LinkTree'))

function Routes() {
    return (
        <BrowserRouter>
            <Suspense fallback={<LinearProgress />}>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/resume" component={Resume} />
                    <Route path="/portfolio" component={Portfolio} />
                    <Route path="/contacts" component={Contacts} />
                    <Route path="/linktree" component={LinkTree} />
                    <Route component={Error} />
                </Switch>
            </Suspense>
        </BrowserRouter>
    )
}

export default Routes
