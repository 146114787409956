import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Routes from './Routes';
function App() {
  return (
    <>
      <CssBaseline />
      <Routes />
    </>
  );
}
export default App;